<template>
  <div>
    功能开发中，敬请期待...
  </div>
</template>
  
<script>

export default {
  name: "PCModule",
  components: {
  },
  data() {
    return {
    };
  },
  methods: {
  },
  mounted() {
  },
};
</script>