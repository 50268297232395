<template>
    <div class="content-box">
        <title-bar2 title="订单详情" />
        <van-cell-group title="资料信息">
            <van-card :thumb="require('@/assets/images/mt/thumb.png')" :centered="false" :title="orderName" :price="orderAmount.toFixed(2)" :desc="'状态：' + orderDesc" num="1" @click="onOrderClick" />
        </van-cell-group>
        <van-cell-group title="订单信息">
            <van-cell title="订单编号：" :value="orderNumber" />
            <van-cell title="下单时间：" :value="orderCreateTime" />
            <van-cell title="付款时间：" :value="orderPayTime" v-if="orderState == 1" />
            <van-cell title="订单状态：" :value="orderDesc" />
        </van-cell-group>
        <van-notice-bar v-if="orderState == 0" wrapable :scrollable="false" left-icon="info-o">请务必仔细阅读试看，确认章节内容无误后再支付，支付后不支持退款！</van-notice-bar>
        <van-submit-bar v-if="orderState == 0" :price="orderAmount * 100" button-text="立即付款" @submit="onSubmit" />
    </div>
</template>
  
<script>
import TitleBar2 from "@/components/MT/TitleBar2";
import Manager from "@/manager";
import Tools from "@/tool";
import Config from "@/api/service";

export default {
    name: "MTModule",
    components: {
        TitleBar2,
    },
    data() {
        return {
            productType: null,
            productGuid: null,
            orderGuid: null,
            orderNumber: null,
            orderName: null,
            orderDesc: null,
            orderCreateTime: null,
            orderPayTime: null,
            orderAmount: 0,
            orderState: 0,
        };
    },
    methods: {
        onBack() {
            this.$router.back();
        },
        //获取路由参数
        getRoutParams() {
            this.orderGuid = this.$route.query.orderGuid;
        },
        //获取订单信息
        getOrderInfo() {
            var model = {
                OrderGuid: this.orderGuid
            };
            this.$axios
                .post(Config.order.info, model)
                .then((res) => {
                    let data = res.data.Data;
                    this.productGuid = data.ProductGuid;
                    this.productType = data.ProductType;
                    this.orderNumber = data.OrderNumber;
                    this.orderName = data.OrderName;
                    this.orderState = data.OrderState;
                    this.orderCreateTime = data.OrderCreateTime;
                    this.orderPayTime = data.OrderPayTime;
                    this.orderAmount = data.OrderAmount;
                    if (this.orderState == 0) {
                        this.orderDesc = '待付款';
                    } else if (this.orderState == 1) {
                        this.orderDesc = '已付款';
                    } else if (this.orderState == 2) {
                        this.orderDesc = '已取消';
                    }
                })
                .catch((error) => {
                    this.$dialog({ title: "错误", message: error.message });
                });
        },
        //点击付款
        onSubmit() {
            this.$dialog
                .confirm({ title: "订单确认", message: "请确认订单信息，付款后不支持退款。" })
                .then(() => {
                    var model = {
                        OrderGuid: this.orderGuid,
                        PayFinishUrl: Manager.goPayFinishUrl(this.$store.state.proxyCode),
                    };
                    this.$axios
                        .post(Config.pay.url, model)
                        .then((res) => {
                            this.$router.push({ name: "PayBefore", query: { payUrl: res.data.Data.DocinPayUrl } });
                        })
                        .catch((error) => {
                            this.$dialog({ title: "错误", message: error.message });
                        });
                }).catch(() => { });
        },
        //点击订单
        onOrderClick() {
            if (this.productType == 0) {
                this.$router.push({
                    name: "Document1",
                    params: {
                        proxyCode: this.$store.state.proxyCode
                    },
                    query: {
                        documentGuid: this.productGuid
                    }
                });
            }
        },
    },
    mounted() {
        this.getRoutParams();
        this.getOrderInfo();
    },
};
</script>

<style scoped>
.content-box {
    background-color: white;
}
</style>